import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { List, Loader, Icon, Popup } from 'semantic-ui-react'
import {
  deleteChannelMembership,
  describeChannel,
  listChannelMemberships,
} from '../../api/ChimeAPI'
import { selectAccount } from '../../redux/account'
import { selectChannelArn, selectChannelName, selectMetadata, selectNewMemberJoined, SetNewMemberJoinChannel } from '../../redux/chimeSlide'
import { CommonService } from '../../services/Common'
import { ReactComponent as Remove } from '../../assets/svg/shield-stop.svg'
import { useTranslation } from 'react-i18next'
import './css/ListMemberChannelModal.css'
import { useAppDispatch } from '../../redux/store'
import Boschicon from '../../common-components/boschIcon/Boschicon'
import { OnlinePresenceStatus } from '../../helpers/AppConstants'
import SignalRService from '../../services/SignalRService'
import { selectTicketState } from '../../redux/ticket'

const ListMemberChannelModal = () => {
  const { t } = useTranslation()
  const channelArn = useSelector(selectChannelArn)
  const account = useSelector(selectAccount)
  const channelName = useSelector(selectChannelName)
  const channelMetaData = useSelector(selectMetadata)
  const newMemberJoined = useSelector(selectNewMemberJoined)
  const [memberList, setMemberList] = useState([])
  const [memberPresenceList, setMemberPresenceList] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteMem, setIsDeleteMem] = useState(false)
  const Customer = ' (' + t('Customer_Label') + ')'
  const BoschExpert = ' (' + t('Bosch_Expert_Label') + ')'
  const dispatchReduxToolkit = useAppDispatch();
  const signalRConnectorInstance = SignalRService.getInstance();
  const ticketState = useSelector(selectTicketState);

  useEffect(() => {
    setIsLoading(true)
    const channelype = channelMetaData ? JSON.parse(channelMetaData)?.channelType : "";
    const getMember = async () => {
      if (channelArn && channelArn !== '' && ticketState) {
        const channelDescribe = await describeChannel(
          channelArn,
          account.chimeUserId,
        )
        const channel = await listChannelMemberships(
          channelArn,
          account.chimeUserId,
        )

        if (channelype === 'PUBLIC') {
          const memberArr = channel.map((item: any) => {
            let showDelete = false
            let isCustomer = false
            //check bosch onwer is current user
            if (
              !item.Member.Arn.includes(account.chimeUserId)
            ) {
              isCustomer = true
              showDelete = true
            }
            return { ...item, showDelete, isCustomer }
          })
          setMemberList(memberArr)
        } else {
          let metaDataOfChannel = channelDescribe.Metadata ? JSON.parse(channelDescribe.Metadata) : "";
          const memberArr = channel.map((item: any) => {
            let showDelete = false
            let isCustomer = false
            //check bosch onwer is current user
            if (channelDescribe.CreatedBy.Arn.includes(account.chimeUserId)) {
              let showDelete = true
              // check member is technician
              if (
                item.Member.Arn.includes(
                  ticketState.ciamId.toLowerCase(),
                ) ||
                item.Member.Arn.includes(metaDataOfChannel.customerCiamId.toLowerCase())
              ) {
                showDelete = false
                isCustomer = true
              }
              if (item.Member.Arn.includes(account.chimeUserId)) {
                showDelete = false
              }

              return { ...item, showDelete, isCustomer }
            }
            if (
              item.Member.Arn.includes(ticketState.ciamId.toLowerCase()) ||
              item.Member.Arn.includes(metaDataOfChannel.customerCiamId.toLowerCase())
            ) {
              isCustomer = true
            }
            return { ...item, showDelete, isCustomer }
          })
          setMemberList(memberArr)
        }

      }
      setIsLoading(false)
      setIsDeleteMem(false)
      dispatchReduxToolkit(SetNewMemberJoinChannel(false));
    }
    getMember()
  }, [channelArn, isDeleteMem, newMemberJoined])

  useEffect(() => {
    if (channelArn) {
      signalRConnectorInstance.onRegisterMessageHandler(
        (message: any) => {
          setMemberPresenceList(message);
        }
      );
    }
  }, [channelArn])

  const renderIconPresenceUser = (item: any) => {
    let newObject = memberPresenceList ? memberPresenceList : null
    const objectB = newObject ? newObject.find((obj: any) => item.Member.Arn.includes(obj.memberARN)) : null;

    if (objectB) {
      switch (objectB.status) {
        case OnlinePresenceStatus.online:
          return (<Popup trigger={<div className='online-icon'><Boschicon name="bosch-ic-dot" /></div>} position="top center">
            {t("Online_Label")}
          </Popup>)
        case OnlinePresenceStatus.offline:
          return (<Popup trigger={<Boschicon name="bosch-ic-abort-frame" />} position="top center">
            {t("Offline_Label")}
          </Popup>)
        case OnlinePresenceStatus.onhold:
          return (<Popup trigger={<div className='onhold-icon'><Boschicon name="bosch-ic-dot" /></div>} position="top center">
            {t("Onhold_Label")}
          </Popup>)
        case OnlinePresenceStatus.busy:
          return (<Popup trigger={<div className='busy-icon'><Boschicon name="bosch-ic-dot" /></div>} position="top center">
            {t("Busy_Label")}
          </Popup>)
        default:
          return (<Popup trigger={<div className='online-icon'><Boschicon name="bosch-ic-dot" /></div>} position="top center">
            {t("Online_Label")}
          </Popup>)
      }
    }

    return (<Popup trigger={<Boschicon name="bosch-ic-abort-frame" />} position="top center">
      {t("Offline_Label")}
    </Popup>)
  }

  const removeMembership = async (memberArn: string) => {
    setIsDeleteMem(true)
    await deleteChannelMembership(channelArn, memberArn, account.chimeUserId);
  }

  useEffect(() => {
    return () => {
      //Code in this area would be fired when component is unmounted
      console.log(`unmounted component`);
      setMemberList([]);
      setMemberPresenceList(null);
      setIsLoading(false);
      setIsDeleteMem(false);
    };
  }, []);

  const checkedName = (item: any) => {
    if (!item?.isCustomer) {
      if (item?.Member.Name.toLowerCase().includes('bosch agent')) {
        return item?.Member.Name + BoschExpert;
      }
      if (item?.Member.Name.toLowerCase().includes('anonymous')) {
        return item?.Member.Name + Customer;
      }
      return item?.Member.Name + BoschExpert;
    }

    return item?.Member.Name + Customer;
  }


  return (
    <Fragment>
      {isLoading ? (
        <div style={{ height: '200px' }}>
          <Loader active />
        </div>
      ) : (
        <Fragment>
          <List className='member-list' divided verticalAlign="middle">
            {memberList &&
              memberList.map((item: any, index) => (
                <div className="member-item" key={index}>
                  <div className="member-icon">
                    {
                      renderIconPresenceUser(item)
                    }
                  </div>
                  <div className="member-name">
                    {
                      checkedName(item)
                    }
                  </div>
                  <div className="member-remove">
                    {item.showDelete && (
                      <div style={{ cursor: 'pointer' }}>
                        <Icon
                          onClick={() => removeMembership(item?.Member.Arn)}
                        >
                          <Remove />
                        </Icon>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </List>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ListMemberChannelModal
