const awsPath = '/Prod';
export const rootPath: string = '/pcs/';
export const landingPagePath: string = '/';

const routes = {
    HOME: `${rootPath}`,
    ASSISTANT: `${landingPagePath}assistant`,
    DEVICE: `${rootPath}devices`,
    CHAT: `${rootPath}publicChat/:sessionId?`,
    CHATPRIVATEANONYMOUS: `${rootPath}privateChat/:sessionId?`,
    MEETING: `${rootPath}meeting/:meeting?`,
    PUBLICMEETING: `${rootPath}publicMeeting/:meeting?`,
    PUBLICCHATASSISTANT: `${landingPagePath}assistant/publicChat/:sessionId?`,
    PUBLICMEETINGASSISTANT: `${landingPagePath}assistant/publicMeeting/:meeting?`,
    SIGNIN: `${rootPath}`,
    LANDINGPAGE: `${landingPagePath}`,
    CorporateInformation: `${landingPagePath}corporate-information/:c?`,
    LegalNotice: `${landingPagePath}legal-notice/:c?`,
    DataProtectionNotice: `${landingPagePath}data-protection-notice/:c?`,
    TermCondition: `${landingPagePath}term-condition`
};

export default routes;