import './semantic/dist/semantic.min.css';
import './App.css';
import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from 'amazon-chime-sdk-component-library-react';
import routes from './routes';
import Header from './components/layout/Header';
import SignInCallback from './scenes/SignInCallback';
import DashBoard from "./components/views/DashBoard/DashBoard";
import SignOutCallback from "./scenes/SignOutCallback";
import SessionTimeout from './scenes/SessionTimeout';
import { Grid } from 'semantic-ui-react';
import WindowMeeting from './components/views/WindowMeeting/WindowMeeting';
import HeaderLandingPage from './components/views/LandingPage/Header/HeaderLandingPage';
import Info from './components/views/LandingPage/Footer/Info/Info';
import TermAndCondition from './components/views/LandingPage/Footer/TermAndCondition/TermAndCondition';
import LandingPageRedirect from './components/views/LandingPage/LandingPageRedirect/LandingPageRedirect';
import PublicDashBoard from './components/views/PublicDashBoard/PublicDashBoard';
import PublicWindowMeeting from './components/views/WindowMeeting/PublicWindowMeeting/PublicWindowMeeting';
import AssistantDashBoard from './components/views/AssistantDashBoard/AssistantDashBoard';
import LandingPage from './components/views/LandingPage/LandingPage';
import PrivateChatAnonymous from './components/views/PrivateChatAnonymous/PrivateChatAnonymous';
import HeaderBoschStyle from './components/views/LandingPage/Header/HeaderBoschStyle';


export default class App extends React.Component<{}> {
  render() {
    return (
      <Router>
        <ThemeProvider theme={lightTheme}>
          <div className="w-screen h-screen flex justify-center p-8" style={{ height: "100%" }}>

            <Switch>
              <Route path="/signincallback" exact component={SignInCallback} />
              <Route path="/signoutcallback" exact component={SignOutCallback} />
              <Route path="/sessiontimeout" exact component={SessionTimeout} />
              <Route path="/permission-denied" exact>
                <Grid>
                  <Grid.Row>
                    <Header />
                  </Grid.Row>
                  <Grid.Row className="grid-responsive-width body-container">
                    <h4>Private Channel Solution</h4>
                  </Grid.Row>
                  <Grid.Row className="grid-responsive-width body-container">
                    <h5>This account doesn't have permission to start channel</h5>
                  </Grid.Row>
                </Grid>
              </Route>
              <Route path={routes.MEETING} exact>
                <Header />
                <WindowMeeting />
              </Route>
              <Route path={routes.PUBLICMEETING} exact>
                <Header />
                <PublicWindowMeeting />
              </Route>
              <Route path={routes.SIGNIN} exact>
                <div className='container-pcs'>
                  <Header />
                  <DashBoard />
                </div>
              </Route>
              <Route path={routes.LANDINGPAGE} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <LandingPage/>
                </div>
              </Route>
              <Route path={routes.CHAT} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <PublicDashBoard />
                </div>
              </Route>
              <Route path={routes.CHATPRIVATEANONYMOUS} exact>
                <div className='container-landingpage'>
                  {/* <HeaderBoschStyle /> */}
                  <PrivateChatAnonymous />
                </div>
              </Route>
              <Route path={routes.PUBLICCHATASSISTANT} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <PublicDashBoard />
                </div>
              </Route>
              <Route path={routes.PUBLICMEETINGASSISTANT} exact>
                <Header />
                <PublicWindowMeeting />
              </Route>
              <Route path={routes.ASSISTANT} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <AssistantDashBoard />
                </div>
              </Route>
              {/* <Route path={routes.CorporateInformation} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <Info />
                </div>
              </Route>
              <Route path={routes.LegalNotice} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <Info />
                </div>
              </Route>
              <Route path={routes.DataProtectionNotice} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <Info />
                </div>
              </Route>
              <Route path={routes.TermCondition} exact>
                <div className='container-landingpage'>
                  <HeaderLandingPage />
                  <TermAndCondition />
                </div>
              </Route> */}
            </Switch>
          </div>
        </ThemeProvider>
      </Router>
    );
  }
}
