import React, { Fragment, useEffect, useState } from 'react';
import {
    ChatBubble,
    ChatBubbleContainer,
    formatDate,
    InfiniteList,
    MessageAttachment
} from 'amazon-chime-sdk-component-library-react';

import {
    listChannelMessages,
    createMemberArn
} from '../../api/ChimeAPI';
import insertDateHeaders from '../../utilities/insertDateHeaders';

import './Messages.css';
import { useAppDispatch } from '../../redux/store';
import { ChannelMessagesState, ChannelSetMessage, MessageState, SetIsTranslate, selectIsTranslate } from '../../redux/chimeSlide';
import { AttachmentService } from '../../services/AttachmentService';
import Attachment from '../../containers/InputChat/Attachment';
import { liveTranslateMessage } from '../../helpers/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CommonService } from '../../services/Common';
import i18n from '../../translations/i18n';
import { Modal } from 'semantic-ui-react';
import { messageListModal, senderENUM } from '../../redux/assistantBot';
import formatTime from '../../services/FormatTime';
interface MessagesProps {
    messages: MessageState,
    channelArn: string,
    userId: string,
    translateModeData: any
}

const Messages = ({ messages, channelArn, userId, translateModeData }: MessagesProps) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const [triggerShowHistoryModal, setTriggerShowHistoryModal] = useState<boolean>(false);
    const [listOnHideHistoryMessage, setListOnHideHistoryMessage] = useState<string[]>([]);
    const [historyChatData, setHistoryChatData] = useState<any>([]);
    const [messageHistoryListState, setMessageHistoryListState] = useState<any>(null);
    const isTranslate = useSelector(selectIsTranslate);
    const dispatchReduxToolkit = useAppDispatch();
    var isLoadImg = false;
    const browserLanguage = i18n.language;
    const resize = (size: string) => {
        let size_num = Number(size)
        return size_num < 1024 ? `${size} bytes` : size_num < 1024 * 1024 ? `${String((size_num / 1024).toFixed(2))} KB` : `${String((size_num / 1024 / 1024).toFixed(2))} MB`
    }

    const onTranslate = async (message: string) => {
        if (isTranslate.status) {
            dispatchReduxToolkit(SetIsTranslate({
                status: false,
                langSource: "auto",
                langTarget: "auto",
                languagebox: false
            }));
        } else {
            let messageDetectLanguage = await CommonService.detectLanguage({ text: message });
            dispatchReduxToolkit(SetIsTranslate({
                status: true,
                langSource: messageDetectLanguage,
                langTarget: browserLanguage,
                languagebox: true
            }));
        }

    }

    const handleScrollTop = async () => {

        if (isLoadImg) {
            isLoadImg = false;
            return;
        }

        setIsLoading(true);
        if (!messages?.NextToken) {
            console.log('No new messages');
            setIsLoading(false);
            return;
        }

        const oldMessages = await listChannelMessages(
            channelArn,
            userId,
            messages.NextToken
        );

        const message_translate = await Promise.all(oldMessages.Messages.map(async (message: any) => {
            if (createMemberArn(userId) !== message.Sender.Arn && translateModeData.isTranslate) {
                // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
                const translateMessage = await liveTranslateMessage(message.Content, translateModeData.langSource, translateModeData.langTarget);
                return { ...message, Content: translateMessage }
            }
            return message;
        }));

        const newMessages = [...message_translate, ...messages?.ChannelMessages ? messages.ChannelMessages : []];

        dispatchReduxToolkit(ChannelSetMessage({ ChannelMessages: newMessages, NextToken: oldMessages.NextToken }));
        // setChannelMessageToken(oldMessages.NextToken);
        setIsLoading(false);
    };

    let lastSeen = -1;
    let lastSent = -1;

    const messageListHistory = (flattenedMessages: messageListModal[], senderName: string) => {
        return flattenedMessages.map((m, i, self) => {
            if (!m.message) {
                return m;
            }

            let metadata: {
                isMeetingInfo: boolean,
                attachment: {
                    fileKey: string,
                    type: string,
                    size: string,
                    name: string
                },
                status: string,
                meeting: {
                    meetingId: string,
                    pathRoute: string
                },
                onStartVoiceCall: boolean
            } = {
                isMeetingInfo: false,
                attachment: {
                    fileKey: '',
                    type: '',
                    size: '',
                    name: ''
                },
                status: '',
                meeting: {
                    meetingId: '',
                    pathRoute: ''
                },
                onStartVoiceCall: false
            };

            if (m.metaData) {
                metadata = JSON.parse(m.metaData);
                if (metadata.isMeetingInfo) {
                    return m;
                };
            }

            //check sender name
            let senderNameDetect = "";
            switch (m.sender) {
                case senderENUM.boschExpert:
                    senderNameDetect = t("BoschExpert_Label_Sender");
                    break;
                case senderENUM.assistant:
                    senderNameDetect = t("Assistant_Label_Bot");
                    break;
                case senderENUM.member:
                    senderNameDetect = senderName || "";
                    break;
                case senderENUM.user:
                    senderNameDetect = senderName || "";
                    break;
                default:
                    break;
            }

            return (
                <Fragment>
                    <ChatBubbleContainer
                        className='chat-bubble-container-them'
                        key={`message${i.toString()}`}
                    >
                        <ChatBubble
                            className='chat-bubble-them'
                            senderName={senderNameDetect}
                            variant={"incoming"}>
                            <div>
                                {m.message}
                            </div>
                        </ChatBubble>
                        <div className='message-time'>{m.datetime}</div>
                    </ChatBubbleContainer>
                </Fragment>
            );
        });
    }

    const handleShowHideHistoryMessage = (massage: any, senderName: string) => {
        let messageId = massage.messageId;
        let checkIncluded = listOnHideHistoryMessage.includes(messageId);



        if (checkIncluded) {
            let messageInMetaData = JSON.parse(massage.metadata).botHistory;
            return (<>
                {
                    messageListHistory(messageInMetaData, senderName)
                }
                <div className={"system_noti_message"}>
                    <a onClick={() => { handleShowHideAction(messageId, false) }}>{t("Hide_Message_label")}</a>
                </div>
            </>)
        } else {
            const senderName = massage.senderName;
            const messageReturn = t("Channel_noti_joined_message").replace("[name]", senderName || "");
            return (<>
                <div className={"system_noti_message"}>
                    {messageReturn} <a onClick={() => { handleShowHideAction(messageId, true) }}>{t("Click_to_view_history_message")}</a>
                </div>
            </>)
        }
    }

    const handleShowHideAction = (messageId: string, onShow: boolean) => {
        if (onShow) {
            let newListMessageId = listOnHideHistoryMessage.concat(messageId);
            setListOnHideHistoryMessage(newListMessageId);
        } else {
            let newListMessageId = listOnHideHistoryMessage.filter(item => item !== messageId);
            setListOnHideHistoryMessage(newListMessageId);
        }
    }



    const removeDuplicatesMessageFunc = (messageList: ChannelMessagesState[]) => {

        const uniqueMessages: { [key: string]: boolean } = {};
        const result: ChannelMessagesState[] = [];

        for (const message of messageList) {
            let metaData = JSON.parse(message.Metadata)?.type;
            if (metaData && metaData === 'history') {
                const key = `${message.Sender.Name}_${metaData}`;
                if (!uniqueMessages[key]) {
                    result.push(message);
                    uniqueMessages[key] = true;
                }
            } else {
                result.push(message);
            }

        }

        return result;
    };
    const fillerDuplicateMessages = removeDuplicatesMessageFunc(messages.ChannelMessages);
    const flattenedMessages = fillerDuplicateMessages.filter((m: ChannelMessagesState, index: number) => {
        const messageWithDeleted = JSON.parse(m.Metadata)?.deleted ? true : false;
        return !messageWithDeleted
    }).map((m: ChannelMessagesState, index: number) => {

        const content = !m.Content || m.Redacted ? '(Deleted)' : m.Content;
        let editedNote;
        if (m.LastEditedTimestamp && !m.Redacted) {
            const time = formatTime(m.LastEditedTimestamp);
            const date = formatDate(
                m.LastEditedTimestamp,
                undefined,
                undefined,
                'today',
                'yesterday'
            );
            editedNote = (
                <i style={{ fontStyle: 'italic' }}>{` (edited ${date} at ${time})`}</i>
            );
        }

        const messageStatus = m.Status.Value == null ? 'SENT' : m.Status.Value;
        let statusNote;
        if (messageStatus !== 'SENT') {
            statusNote = (
                <i style={{ fontStyle: 'italic' }}>{`     (${messageStatus})`}</i>
            );
        } else {
            lastSent = index;
        }

        if (m.Metadata && m.Sender.Arn === createMemberArn(userId)) {
            lastSeen = JSON.parse(m.Metadata)?.status === "Read" ? index : lastSeen;
        }

        return {
            content: content,
            editedNote: editedNote,
            messageId: m.MessageId,
            createdTimestamp: m.CreatedTimestamp,
            redacted: m.Redacted,
            senderName: m.Sender.Name,
            senderId: m.Sender.Arn,
            metadata: m.Metadata,
            status: m.Status.Value,
            statusNote: statusNote
        };
    });

    const attachmentService = AttachmentService.getInstance();

    const onloadimg = () => {
        isLoadImg = true;
    }

    const listItems = insertDateHeaders(flattenedMessages);

    const messageList = listItems.map((m, i, self) => {

        if (!m.content) {
            return m;
        }

        let hasAttachment = -1;

        let metadata: {
            isMeetingInfo: boolean,
            attachment: {
                fileKey: string,
                type: string,
                size: string,
                name: string
            },
            status: string,
            meeting: {
                meetingId: string,
                pathRoute: string
            },
            onStartVoiceCall: boolean,
            botHistory: any,
            type: string
        } = {
            isMeetingInfo: false,
            attachment: {
                fileKey: '',
                type: '',
                size: '',
                name: ''
            },
            status: '',
            meeting: {
                meetingId: '',
                pathRoute: ''
            },
            onStartVoiceCall: false,
            botHistory: null,
            type: ''
        };

        let preSignURL;
        let isHistoryMessage = false

        if (m.metadata) {
            metadata = JSON.parse(m.metadata);

            if (metadata.type === "history") {
                isHistoryMessage = true
            }

            if (metadata.isMeetingInfo) {
                return m;
            };

            if (metadata?.attachment?.fileKey) {
                preSignURL = attachmentService.getPresignURL(metadata.attachment.fileKey);
                if (metadata.attachment?.type.includes('image')) {
                    hasAttachment = 0;
                } else {
                    hasAttachment = 1;
                }
            }
        }

        const variant = createMemberArn(userId) === m.senderId ? 'outgoing' : 'incoming';

        const prevMessage = self[i - 1];
        const nextMessage = self[i + 1];
        // if (!m.content) {
        //     nextMessage = self[i + 2];
        // }

        let statusMessageShow = true;
        let showName = true;
        let createdTimestamp = null;

        if (m.senderId && prevMessage) {
            let prevMessageMetadata = prevMessage.metadata ? JSON.parse(prevMessage.metadata) : null;
            let isPrevHistoryMessage = prevMessageMetadata ? (prevMessageMetadata.type === 'history' ? true : false) : false;
            if (prevMessage?.senderId && m.senderId === prevMessage?.senderId && !isPrevHistoryMessage) {
                showName = false;
            }
        }

        createdTimestamp = formatTime(m.createdTimestamp);

        if (m.senderId && nextMessage?.senderId && m.senderId === nextMessage?.senderId) {
            try {

                if (nextMessage.status === m.status) {
                    statusMessageShow = false;
                }
                //TODO:
                // if (nextMessage.metadata != m.metadata) {
                //     statusMessageShow = true;
                // }

                if (JSON.parse(nextMessage.metadata || { status: "" }).status != metadata.status) {
                    statusMessageShow = true;
                }

                if ((Math.abs(nextMessage.createdTimestamp.getTime() - m.createdTimestamp.getTime()) / 1000 / 60) > 1) {
                    createdTimestamp = formatTime(m.createdTimestamp);
                } else {
                    createdTimestamp = null;
                }
            } catch {
                createdTimestamp = null;
            }
        }

        if (metadata.status !== "" && i < (lastSeen + (self.length - flattenedMessages.length) - 1)) {
            statusMessageShow = false;
        }
        if (metadata.status == "" && (i <= (lastSent + (self.length - flattenedMessages.length) - 1) || i <= (lastSeen + (self.length - flattenedMessages.length) - 1))) {
            statusMessageShow = false;
        }
        if (nextMessage) {
            statusMessageShow = false;
        }
        if (variant === 'outgoing' && statusMessageShow)

            if (metadata.status) {
                createdTimestamp = createdTimestamp ? createdTimestamp + " (Read)" : "(Read)";
            } else {
                createdTimestamp = createdTimestamp ? createdTimestamp + " (Sent)" : "(Sent)";
            }

        return (
            <div className="message">
                {
                    !isHistoryMessage ? (
                        <>
                            {variant === 'outgoing' ? <>
                                <ChatBubbleContainer
                                    className='chat-bubble-container-mine'
                                    timestamp={createdTimestamp}
                                    key={`message${i.toString()}`}>

                                    <ChatBubble
                                        className='chat-bubble-mine'
                                        variant={variant}>
                                        {hasAttachment !== -1 &&
                                            <div style={{ display: 'flex' }}>
                                                {hasAttachment === 0 &&
                                                    <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`} />
                                                }
                                                {hasAttachment === 1 &&
                                                    <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} />
                                                }
                                            </div>
                                        }
                                        {m.content}
                                        {metadata?.meeting?.meetingId && <a style={{ color: 'white', cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting?.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}>Link</a>}

                                    </ChatBubble>
                                </ChatBubbleContainer>
                            </> :
                                <ChatBubbleContainer
                                    className='chat-bubble-container-them'
                                    key={`message${i.toString()}`}>
                                    {showName ?
                                        <>
                                            <ChatBubble
                                                className='chat-bubble-them'
                                                variant={variant}
                                                senderName={m.senderName}>
                                                {hasAttachment !== -1 &&
                                                    <div style={{ display: 'flex' }}>
                                                        {hasAttachment === 0 &&
                                                            <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`} />
                                                        }
                                                        {hasAttachment === 1 &&
                                                            <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} />
                                                        }
                                                    </div>
                                                }
                                                <div>
                                                    {m.content}
                                                    {metadata?.meeting?.meetingId && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}>Link</a>}
                                                    {metadata?.botHistory && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} onClick={() => hanldeOpenShowHistoryModal(metadata.botHistory)}> View</a>}
                                                    {
                                                        ((m.content.trim() !== "")) && (<>
                                                            <br /><br />
                                                            <a style={{ cursor: 'pointer', textDecorationLine: 'none', marginTop: '5px' }} target={"_blank"} onClick={() => onTranslate(m.content)}>{isTranslate.status ? t('Dis_translation') : t('En_translation')}</a>
                                                        </>)

                                                    }
                                                </div>
                                            </ChatBubble>
                                            <div className='message-time'>{createdTimestamp}</div>
                                        </>

                                        :
                                        <>
                                            <ChatBubble
                                                className='chat-bubble-them'
                                                variant={variant}>
                                                <div>
                                                    {hasAttachment !== -1 &&
                                                        <div style={{ display: 'flex' }}>
                                                            {hasAttachment === 0 &&
                                                                <MessageAttachment imgOnLoad={onloadimg} renderImg={true} name={metadata.attachment.name} size={resize(metadata.attachment.size)} imgUrl={preSignURL !== undefined ? preSignURL : ""} downloadUrl={`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + metadata.attachment.fileKey + `/${metadata.attachment.name}`} />
                                                            }
                                                            {hasAttachment === 1 &&
                                                                <Attachment fileKey={metadata.attachment.fileKey} name={metadata.attachment.name} size={resize(metadata.attachment.size)} isImage={false} />
                                                            }
                                                        </div>
                                                    }
                                                    {m.content}
                                                    {metadata?.meeting?.meetingId && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} href={metadata?.meeting.pathRoute + metadata?.meeting.meetingId + `/?user_id=${userId}` + `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`} target={"_blank"}>Link</a>}
                                                    {metadata?.botHistory && <a style={{ cursor: 'pointer', fontStyle: 'italic', textDecorationLine: 'underline' }} onClick={() => hanldeOpenShowHistoryModal(metadata.botHistory)}>View</a>}
                                                    {
                                                        ((m.content.trim() !== "")) && (<>
                                                            <br /><br />
                                                            <a style={{ cursor: 'pointer', textDecorationLine: 'none', marginTop: '5px' }} target={"_blank"} onClick={() => onTranslate(m.content)}>{isTranslate.status ? t('Dis_translation') : t('En_translation')}</a>
                                                        </>)

                                                    }

                                                </div>
                                            </ChatBubble>
                                            <div className='message-time'>{createdTimestamp}</div>
                                        </>

                                    }
                                </ChatBubbleContainer>
                            }
                        </>
                    ) : (
                        <>
                            {handleShowHideHistoryMessage(m, m.senderName)}
                        </>
                    )
                }
            </div>
        );
    });


    useEffect(() => {
        if (historyChatData && historyChatData.length > 0) {
            const messageHistoryList = historyChatData.map((m: any, i: any) => {
                if (!m.message || m.sender === senderENUM.system) {
                    return <></>;
                }

                const variant = m.sender === senderENUM.user ? "outgoing" : "incoming";

                return (
                    <div className="message">
                        {variant === "outgoing" ? (
                            <>
                                <ChatBubbleContainer
                                    timestamp={m.datetime}
                                    key={`message${i.toString()}`}
                                    css="
                                  background-color: #fff;
                                  margin: 0;
                                  display: flex;
                                  align-items: end;
                                  flex-direction: column;
                                  "
                                >
                                    <ChatBubble
                                        variant={variant}
                                        css="border-radius: 0px;
                                      background-color: var(--boschBlue50);
                                      color: #fff;
                                      "
                                    >
                                        <div>
                                            {m.message}
                                        </div>
                                    </ChatBubble>
                                </ChatBubbleContainer>
                            </>
                        ) : (
                            <Fragment>
                                {m.sender === senderENUM.system ? (
                                    <div className="">
                                        <p>{m.message}</p>
                                    </div>
                                ) :
                                    <div className="{Style.message_bubble}">
                                        <ChatBubbleContainer
                                            key={`message${i.toString()}`}
                                            css="
                          margin: 0;
                                      background-color: #fff;
                                      display: flex;
                                      align-items: start;
                                      flex-direction: column;
                                      "
                                        >
                                            <ChatBubble

                                                variant={variant}
                                                css="border-radius: 0px;
                                      background-color: #e0e2e5;
                                      color: rgba(0,0,0,.87);
                                      ">
                                                <div>
                                                    {m.message}
                                                </div>
                                            </ChatBubble>
                                            <div className="">{m.datetime}</div>
                                        </ChatBubbleContainer>
                                    </div>
                                }
                            </Fragment>
                        )}
                    </div>
                );
            });
            setMessageHistoryListState(messageHistoryList)
        }
    }, [historyChatData])


    const hanldeOpenShowHistoryModal = (massage: any) => {
        setTriggerShowHistoryModal(true)
        setHistoryChatData(massage);
    }

    return (
        <div className="message-list-container">
            {(messages) &&
                <InfiniteList
                    style={{ overflow: 'hidden', backgroundColor: '#fff' }}
                    items={messageList}
                    onLoad={handleScrollTop}
                    isLoading={isLoading}
                />
            }
            {
                messageList.length == 0 && (
                    <div className='none-message-note'>
                        <p>{t("Write_something")}</p>
                    </div>
                )
            }
            {
                (triggerShowHistoryModal && messageHistoryListState) &&
                (
                    <Modal
                        open={triggerShowHistoryModal}
                        size={"large"}
                        closeIcon
                        onClose={() => setTriggerShowHistoryModal(false)}
                    >
                        <div className="customModal">
                            <div className="error-area">
                                <div className="error--title">
                                </div>
                                <div className="error--content">
                                    <p>
                                        <InfiniteList
                                            style={{ overflow: 'hidden', backgroundColor: '#fff' }}
                                            items={messageHistoryListState}
                                            onLoad={handleScrollTop}
                                            isLoading={isLoading}
                                        />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </Modal>
                )
            }
        </div>
    );
};
export default Messages;