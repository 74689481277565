export const TRANSLATIONS_NL = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Nieuwe sessie",
	Start_New_Question: "Hoe had u graag een nieuwe sessie gestart?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket nummer",
	Code_label: "Code",
	Channel_List: "Kanaallijst",
	Chat_Label: "Chat",
	Call_Label: "Gesprek",
	Video_Label: "Video",
	Share_Label: "Delen",
	Stop_Channel_Label: "Stop kanaal",
	Contact_Details_Label: "Contactgegevens",
	Request_Details_Label: "Gegevens aanvraag",
	Attendees_Label: "Deelnemers",
	Customer_Label: "Klant",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Typ je boodschap",
	Stop_Channel_Question: "Wil je het kanaal sluiten?",
	Yes_Label: "Ja",
	Cancel_Label: "Annuleren",
	Start_New_Video_Session: "Nieuwe video sessie starten",
	Without_SMS_Label: "Zonder SMS",
	Send_SMS_Label: "Stuur SMS",
	Send_Invite_Label: "Stuur uitnodiging",
	Log_Out_Question: "Wil je uitloggen?",
	Welcome_Label: "Welcome in de Private Channel Solution",
	Login_Label: "Login",
	Login_Is_Required_Label: "Login is vereist om het kanaal te starten",
	Account_Permission_Label: "Deze account heeft geen toestemming om een kanaal te starten",
	Start_Label: "Start",
	Noted_Recommend: "Door de voorwaarden van Bosch omtrent het gebruik van Firefox raden we aan een andere browser te gebruiken om een betere gebruikservaring te hebben. Aangeraden browsers: Microsoft Edge & Google Chrome",
	Available_Languages_Label: "Beschikbare talen",
	Can_Not_Create_Meeting_Title: "Niet mogelijk een nieuwe sessie te starten",
	Can_Not_Create_Meeting_Content: "U bent reeds actief in een bestaande sessie. Sluit die sessie eerst af alvorens een nieuwe te starten.",
	Leave_meeting_Warning: "Sessie verlaten of sessie bëëindigen voor allen. De sessie kan niet opnieuw gebruikt worden",
	Leave_Lable: "Verlaten",
	End_Lable: "Beëindigen",
	Meeting_Ended: "The meeting is beëindigd",
	Start_Channel_Session: "Start sessie",
	Start_Channel_Session_Content: "De technieker is momenteel bezig met een andere sessie met Ticket ID:",
	Join_Lable: "Deelnemen",
	Join_Meeting_Label: "Deelnemen aan sessie",
	Meeting_Information_Label: "Sessieinformatie",
	Meeting_ID_Label: "Sessie ID",
	Translate_Option_Title: "Vertaal optie",
	Translate_Mode_Label: "Vertaalmodus:",
	From_Label: "Van:",
	To_Label: "Naar:",
	Chat_Room_Label: "Privé sessie",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Hoe wilt u de privé sessie uitnodiging delen?",
	Invite_To_Visual_Question: "Hoe wilt u de Visual Connect uitnodiging delen?",
	Invite_Sms_Question: "Stuur uitnodiging via SMS",
	Invite_To_Chat_By_Ticket_ID: "Stuur uitnodiging via Ticket ID",
	Invite_Email_Question: "Stuur uitnodiging via mail",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applicaties",
	WSA_Remote_Diagnostics_label: "Remote Diagnose",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Website openen",
	WSA_Slogan_label: "Technologie voor het leven",
	WSA_contact_info_label: "Algemene contactgegevens",
	WSA_PSIRT_label: "Productveiligheid",
	WSA_licences_label: "Innovaties, patenten en licenties",
	WSA_purchasing_label: "Aankoop en logistiek",
	WSA_copyright_label: "© Robert Bosch GmbH, alle rechten voorbehouden",
	WSA_copyright_information_label: "Bedrijfsinformatie",
	WSA_copyright_notice_label: "Wettelijke kennisgeving",
	WSA_copyright_protectionnotice_label: "Kennisgeving gegevensbescherming",
	WSA_copyright_terms_label: "Algemene voorwaarden",
	WSA_copyright_settings_label: "Privacy instellinge",
	Infor_CorporateInformation_Description: "Verantwoordelijk voor de internet pagina's van Robert Bosch Gmbh",
	Infor_DataProtectionNotice_Description: "Kennisgeving gegevensbescherming voor de Bosch Remote Diagnose service",
	Infor_LegalNotice_Description: "Voor gebruikers van de internetpagina's van Robert Bosch Gmbh",
	Id_panel: "ID",
	Topic_panel: "Onderwerp",
	Make_panel: "Merk",
	Model_pane: "Model",
	Engine_code_panel: "Motorcode",
	Year_panel: "Modeljaar",
	Vin_panel: "Chassisnummer",
	Plate_panel: "Nummerplaat",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Uw toestel is niet beschikbaar voor AR",
	Write_something: "Schrijf iets om de chat te beginnen",
	Session_title: "Sessie",
	Live_Translation: "Live vertaling",
	Enabled_panel: "Actief",
	Joined_note: "U bent toegevoegd aan de sessie..",
	Today_panel: "Vandaag",
	Read_panel: "Gelezen",
	Sent_panel: "Verzonden",
	Dis_translation: "Vertaling beëindigen",
	En_translation: "Vertaling activeren",
	Terminated_Channel_note: "Bosch Expert heeft het kanaal gesloten!",
	Not_start_note: "nog niet begonnen!",
	Retry_note: "Gelieve op 'Opnieuw proberen' te klikken of dit venster af te sluiten op te beëindigen",
	Retry_panel: "Opnieuw proberen",
	Thanks_note: "Bedankt om de Bosch technische ondersteuning te gebruiken. Indien u verdere hulp wil kan u ons steeds contacteren. Verder een prettige dag gewenst!",
	Continue_panel: "Verdergaan",
	En_live_translate: "Live vertaling inschakelen",
	Translate_from: "Vertaal van",
	Translate_to: "Veraal naar",
	Random_ID: "Willekeurig ID",
	Anonymous: "Anoniem",
	Generated_ID: "Willekeurige ID is gegenereerd!",
	Your_ID: "Uw willekeurige ID is:"
};