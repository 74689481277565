import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ChimeUser from "../models/ChimeUser";
import ResponseBase from "../models/ResponseBase";
import httpPCS from "../services/HttpServicePCS";
import { AppDispatch, RootState } from "./store";

export interface MessageState {
  ChannelMessages: ChannelMessagesState[] | any[],
  NextToken: string | null
}

export interface ChannelMessagesState {
  Content: any,
  Redacted: any,
  LastEditedTimestamp: string,
  Status: { Value: string; },
  MessageId: any,
  CreatedTimestamp: any,
  Sender: {
    Name: any,
    Arn: any
  },
  Metadata: any;
}
export interface presenceUsersSate {
  name: string,
  userArn: string,
  status: string,
  updatedTimeStamp: string
}

export interface ChannelState {
  messages: MessageState,
  ChannelArn: string,
  LastMessageTimestamp: any | null,
  Metadata: string,
  Mode: string,
  Name: string,
  Privacy: string,
  CreatedBy: {
    Arn: string,
    Name: string
  },
  CreatedTimestamp: object | null,
  LastUpdatedTimestamp: any | null,
  ChannelFlowArn: string,
  newMemmberJoin: boolean,
  IsTranslate: {
    status: boolean,
    langSource: string
    langTarget: string,
    languagebox: boolean
  },
  messageWithLinkId: string,
  onHanldeClearMeeting: boolean
  onHanldeLeaveMeeting: boolean
  meetingIDOnLive: string,
  presenceOfUserInChannel: presenceUsersSate[] | [],
  triggerCountDownInChannel: boolean
}

export const initialMessageState: MessageState = {
  ChannelMessages: [],
  NextToken: null
}

export const initialState: ChannelState = {
  messages: initialMessageState,
  ChannelArn: '',
  LastMessageTimestamp: null,
  Metadata: '',
  Mode: '',
  Name: '',
  Privacy: '',
  CreatedBy: {
    Arn: '',
    Name: ''
  },
  CreatedTimestamp: null,
  LastUpdatedTimestamp: null,
  ChannelFlowArn: '',
  newMemmberJoin: false,
  IsTranslate: {
    status: false,
    langSource: "auto",
    langTarget: "auto",
    languagebox: false
  },
  messageWithLinkId: "",
  onHanldeClearMeeting: false,
  onHanldeLeaveMeeting: false,
  meetingIDOnLive: "",
  presenceOfUserInChannel: [],
  triggerCountDownInChannel: false
};

export const getChimeUser = createAsyncThunk<
  ResponseBase<ChimeUser> | null,
  any,
  {
    dispatch: AppDispatch,
    state: RootState,
    rejectValue: AxiosError
  }>(
    "chime/checkChimeUser",
    async (chimeUser, thunkApi) => {
      try {

        const headers = {
          ApiKey: process.env.REACT_APP_PCS_KEY || '',
        }
        var response = await httpPCS.post<ResponseBase<ChimeUser>>(`/api/Chime/ChimeUserAccount/`, chimeUser, { headers });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return thunkApi.rejectWithValue(error);
        }
      }
      return null;
    }
  )

export const selectChannelState = (rootState: RootState) => rootState.channel;
export const selectChannelArn = (rootState: RootState) => selectChannelState(rootState).ChannelArn;
export const selectChannelMessage = (rootState: RootState) => selectChannelState(rootState).messages;
export const selectChannelName = (rootState: RootState) => selectChannelState(rootState).Name;
export const selectNewMemberJoined = (rootState: RootState) => selectChannelState(rootState).newMemmberJoin;
export const selectIsTranslate = (rootState: RootState) => selectChannelState(rootState).IsTranslate;
export const selectMetadata = (rootState: RootState) => selectChannelState(rootState).Metadata;
export const selectMessageWithLinkId = (rootState: RootState) => selectChannelState(rootState).messageWithLinkId;
export const selectOnHanldeClearMeeting = (rootState: RootState) => selectChannelState(rootState).onHanldeClearMeeting;
export const selectOnHanldeLeaveMeeting = (rootState: RootState) => selectChannelState(rootState).onHanldeLeaveMeeting;
export const selectMeetingIDOnLive = (rootState: RootState) => selectChannelState(rootState).meetingIDOnLive;
export const selectPresenceOfUserInChannel = (rootState: RootState) => selectChannelState(rootState).presenceOfUserInChannel;
export const selectTriggerCountDownInChannel = (rootState: RootState) => selectChannelState(rootState).triggerCountDownInChannel;

const channelReducerSlice = createSlice({
  name: 'channelReducer',
  initialState: initialState,
  reducers: {
    channelSetChannel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ChannelArn: action.payload.ChannelArn,
        Name: action.payload.Name,
        CreatedBy: action.payload.CreatedBy,
        CreatedTimestamp: action.payload.CreatedTimestamp,
        LastUpdatedTimestamp: action.payload.LastUpdatedTimestamp,
        messages: initialMessageState,
        Metadata: action.payload.Metadata,
      }
    },
    ChannelSetMessage: (state, action: PayloadAction<MessageState>) => {
      return {
        ...state,
        messages: action.payload
      }
    },
    ChannelClearState: (state, action: PayloadAction) => {
      return {
        ...state,
        messages: initialMessageState,
        ChannelArn: '',
        LastMessageTimestamp: null,
        Metadata: '',
        Mode: '',
        Name: '',
        Privacy: '',
        CreatedBy: {
          Arn: '',
          Name: ''
        },
        CreatedTimestamp: null,
        LastUpdatedTimestamp: null,
        ChannelFlowArn: '',
        presenceOfUserInChannel: [],
        triggerCountDownInChannel: false
      }
    },
    SetNewMemberJoinChannel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        newMemmberJoin: action.payload
      }
    },
    SetIsTranslate: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        IsTranslate: action.payload
      }
    },
    SetMessageWithLinkId: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        messageWithLinkId: action.payload
      }
    },
    SetOnHanldeClearMeeting: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        onHanldeClearMeeting: action.payload
      }
    },
    SetOnHanldeLeaveMeeting: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        onHanldeLeaveMeeting: action.payload
      }
    },
    SetMeetingIDOnLive: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        meetingIDOnLive: action.payload
      }
    },
    SetTriggerCountDownInChannel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        triggerCountDownInChannel: action.payload
      }
    },
    SetPresenceOfUserInChannel: (state, action: PayloadAction<any>) => {
      let mappingArray: presenceUsersSate[] = [...current(state.presenceOfUserInChannel)];
      let message = action.payload;
      let newUpdated: presenceUsersSate = {
        name: "",
        userArn: "",
        status: "",
        updatedTimeStamp: ""
      }
      newUpdated.name = message.Sender.Name;
      newUpdated.status = message.Content.toLowerCase();
      newUpdated.updatedTimeStamp = message.CreatedTimestamp;
      newUpdated.userArn = message.Sender.Arn;
      if (mappingArray) {
        const index = mappingArray.findIndex(obj => obj.userArn === message.Sender.Arn && obj.name === message.Sender.Name);
        if (index !== -1) {
          mappingArray[index] = newUpdated;
        } else {
          mappingArray.push(newUpdated);
        }
      }
      return {
        ...state,
        presenceOfUserInChannel: mappingArray
      }
    },
    SetCleanPresenceOfUserInChannel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        presenceOfUserInChannel: []
      }
    },
  }
});

export const { channelSetChannel, ChannelSetMessage, ChannelClearState, SetNewMemberJoinChannel, SetIsTranslate, SetTriggerCountDownInChannel, SetMessageWithLinkId, SetOnHanldeClearMeeting, SetOnHanldeLeaveMeeting, SetMeetingIDOnLive, SetPresenceOfUserInChannel, SetCleanPresenceOfUserInChannel } = channelReducerSlice.actions;
export default channelReducerSlice.reducer;