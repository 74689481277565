export const TRANSLATIONS_CZ = {
	Logo_Title_Label: "Private Channel Solution - soukromý rozhovor",
	Service_Assist_Label: "Servisní asistent",
	Start_New_LabelBtn: "Nový rozhovor",
	Start_New_Question: "Jak byste chtěli spustit novou soukromou komunikaci?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "ID/ číslo tiketu",
	Code_label: "Kód",
	Channel_List: "Seznam komunikačních způsobů",
	Chat_Label: "Povídat si",
	Call_Label: "Volání",
	Video_Label: "Video",
	Share_Label: "Sdílení",
	Stop_Channel_Label: "Ukončit komunikaci",
	Contact_Details_Label: "Kontaktní údaje",
	Request_Details_Label: "Požadovat detaily",
	Attendees_Label: "Účastníci",
	Customer_Label: "Zákazník",
	Bosch_Expert_Label: "Expert Bosch",
	Type_Your_Message_Label: "Napište svou zprávu",
	Stop_Channel_Question: "Chcete ukončit komunikaci?",
	Yes_Label: "Ano",
	Cancel_Label: "Zrušení",
	Start_New_Video_Session: "Zahájit novou video komunikaci",
	Without_SMS_Label: "Bez SMS",
	Send_SMS_Label: "Pošli SMS",
	Send_Invite_Label: "Poslat pozvánku",
	Log_Out_Question: "Chcete se odhlásit?",
	Welcome_Label: "Vítejte v Soukromém rozhovoru",
	Login_Label: "Přihlásit se",
	Login_Is_Required_Label: "Ke spuštění komunikace je nutné přihlášení",
	Account_Permission_Label: "Tento účet nemá oprávnění ke spuštění komunikace",
	Start_Label: "Start",
	Noted_Recommend: "Kvůli zásadám společnosti Bosch pro používání Firefoxu vám doporučujeme použít jiný prohlížeč, abyste získali co nejlepší uživatelský zážitek z aplikace Private Channel Solution. Doporučené prohlížeče: Microsoft Edge a Google Chrome",
	Available_Languages_Label: "Dostupné jazyky",
	Can_Not_Create_Meeting_Title: "Nelze vytvořit novou soukromou komunikaci",
	Can_Not_Create_Meeting_Content: "Stále jste v aktivní soukromé komunikaci. Před zahájením nové komunikace ukončete aktuální komunikaci.",
	Leave_meeting_Warning: "Opusťte soukromou komunikaci nebo můžete komunikaci ukončit pro všechny. Jakmile komunikace skončí, nelze ji znovu otevřít.",
	Leave_Lable: "Odejít",
	End_Lable: "Konec",
	Meeting_Ended: "Schůzka byla ukončena",
	Start_Channel_Session: "Spusťte komunikaci",
	Start_Channel_Session_Content: "Technik momentálně řeší jiný tiket:",
	Join_Lable: "Připojit",
	Join_Meeting_Label: "Připojte se ke komunikaci",
	Meeting_Information_Label: "Informace o komunikačním kanálu",
	Meeting_ID_Label: "ID komunikace",
	Translate_Option_Title: "Možnost překladu",
	Translate_Mode_Label: "Režim překladu:",
	From_Label: "Z:",
	To_Label: "Na:",
	Chat_Room_Label: "Soukromá komunikace",
	Visual_Connect_Label: "Vizuální připojení",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Jak chcete sdílet pozvánku do soukromé komunikace?",
	Invite_To_Visual_Question: "Jak chcete sdílet pozvánku Visual Connect?",
	Invite_Sms_Question: "Pošlete pozvánku SMS",
	Invite_To_Chat_By_Ticket_ID: "Odeslat pozvánku pomocí ID tiketu",
	Invite_Email_Question: "Odeslat pozvánku e-mailem",
	Link_label: "Odkaz",
	WSA_label: "Workshop Service Assist - asistent autoservisu",
	WSA_Applications_label: "Aplikace",
	WSA_Remote_Diagnostics_label: "Vzdálená diagnostika",
	WSA_Service_Training_label: "Servisní školení",
	WSA_Enter_website_label: "Zadejte webovou stránku",
	WSA_Slogan_label: "Stvořeno pro život",
	WSA_contact_info_label: "Obecné kontaktní údaje",
	WSA_PSIRT_label: "Zabezpečení produktu (PSIRT)",
	WSA_licences_label: "Inovace, patenty a licence",
	WSA_purchasing_label: "Nákup a logistika",
	WSA_copyright_label: "© Robert Bosch GmbH, všechna práva vyhrazena",
	WSA_copyright_information_label: "Informace o společnosti",
	WSA_copyright_notice_label: "Zákonné oznámení",
	WSA_copyright_protectionnotice_label: "Upozornění na ochranu údajů",
	WSA_copyright_terms_label: "Všeobecné obchodní podmínky",
	WSA_copyright_settings_label: "Nastavení ochrany osobních údajů",
	Infor_CorporateInformation_Description: "Zodpovědný za internetové stránky Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Upozornění za ochranu dat pro službu Bosch Remote Diagnostics Service (Vzdálená diagnostika Bosch)",
	Infor_LegalNotice_Description: "Pro uživatele internetových stránek Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Téma",
	Make_panel: "Značka",
	Model_pane: "Model",
	Engine_code_panel: "Kód motoru",
	Year_panel: "Rok",
	Vin_panel: "VIN",
	Plate_panel: "SPZ",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Vaše zařízení není pro AR dostupné",
	Write_something: "Napište něco, abychom mohli začít komunikaci",
	Session_title: "Setkání",
	Live_Translation: "Živý překlad",
	Enabled_panel: "Povoleno",
	Joined_note: "Připojili jste se k relaci…",
	Today_panel: "Dnes",
	Read_panel: "Číst",
	Sent_panel: "Odesláno",
	Dis_translation: "Zakázat překlad",
	En_translation: "Povolit překlad",
	Terminated_Channel_note: "Bosch Expert ukončil komunikaci",
	Not_start_note: "ještě nezačalo",
	Retry_note: "Klikněte na „Opakovat“ a zkuste to znovu, nebo zavřete toto okno pro ukončení.",
	Retry_panel: "Zkuste to znovu",
	Thanks_note: "Děkujeme, že využíváte technické služby Bosch. Jsem velmi rádi, že jsme dnes měli příležitost vám pomoci. Pokud opět budete potřebujet pomoci, kontaktujte nás. Přejeme Vám krásný den.",
	Continue_panel: "Povolit živý překlad",
	En_live_translate: "Pokračovat",
	Translate_from: "Přeložit z",
	Translate_to: "Přeložit do",
	Random_ID: "Náhodné ID",
	Anonymous: "Anonymní",
	Generated_ID: "Náhodné ID bylo vygenerováno!",
	Your_ID: "Vaše náhodné ID je:"
};