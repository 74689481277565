export const TRANSLATIONS_DA = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Opret ny kanal",
	Start_New_Question: "Hvordan vil du oprette en ny Private Channel?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Kode",
	Channel_List: "Kanalliste",
	Chat_Label: "Chat",
	Call_Label: "Opkald",
	Video_Label: "Video",
	Share_Label: "Del",
	Stop_Channel_Label: "Stop kanal",
	Contact_Details_Label: "Kontaktdetaljer",
	Request_Details_Label: "Forespørgselsdetaljer",
	Attendees_Label: "Deltagere",
	Customer_Label: "Kunde",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Indtast din besked",
	Stop_Channel_Question: "Ønsker du at lukke kanalen?",
	Yes_Label: "Jan",
	Cancel_Label: "Afbryd",
	Start_New_Video_Session: "Opret videoopkald",
	Without_SMS_Label: "Uden SMS",
	Send_SMS_Label: "Send SMS",
	Send_Invite_Label: "Send invitation",
	Log_Out_Question: "Ønsker du at logge ud?",
	Welcome_Label: "Velkommen til Private Channel Solution",
	Login_Label: "Log ind",
	Login_Is_Required_Label: "Log ind er påkrævet for at oprette en kanal",
	Account_Permission_Label: "Denne konto har ikke de nødvendige rettigheder for at oprette en kanal",
	Start_Label: "Start",
	Noted_Recommend: "På grund af Boschs politik for brug af Firefox anbefaler vi, at du bruger en anden browser for at få den bedste brugeroplevelse af Private Channel Solution-applikationen. Anbefalede browsere: Microsoft Edge og Google Chrome",
	Available_Languages_Label: "Tilgængelige sprog",
	Can_Not_Create_Meeting_Title: "Kunne ikke oprette en ny privat kanal",
	Can_Not_Create_Meeting_Content: "Du er stadig i en aktiv privat kanal. Afslut venligst den aktuelle kanal, før du starter en ny.",
	Leave_meeting_Warning: "Forlad den private kanal, eller du kan afslutte kanalen for alle. Kanalen kan ikke bruges, når den først slutter.",
	Leave_Lable: "Forlad",
	End_Lable: "Afslut",
	Meeting_Ended: "Mødet er afsluttet",
	Start_Channel_Session: "Start kanal",
	Start_Channel_Session_Content: "Teknikeren er i øjeblikket i gang med ticket ID:",
	Join_Lable: "Deltag",
	Join_Meeting_Label: "Deltag i kanal",
	Meeting_Information_Label: "Kanal information",
	Meeting_ID_Label: "Kanal ID",
	Translate_Option_Title: "Oversættelsesmuligheder",
	Translate_Mode_Label: "Oversættelse:",
	From_Label: "Fra:",
	To_Label: "Til:",
	Chat_Room_Label: "Privat Kanal",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	WSA_label: "Workshop Service Assist",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	Id_panel: "Id",
	Topic_panel: "Emne",
	Make_panel: "Mærke",
	Model_pane: "Model",
	Engine_code_panel: "Motorkode",
	Year_panel: "Årgang",
	Vin_panel: "Stelnr.",
	Plate_panel: "Nr.plade",
	Hardware_panel: "Hardware",
	Today_panel: "Idag",
	Read_panel: "Læst",
	Sent_panel: "Sendt",
	Dis_translation: "Deaktiver oversættelse",
	En_translation: "Oversæt",
	Continue_panel: "Fortsæt",
	En_live_translate: "Aktiver oversættelse",
	Translate_from: "Oversæt fra",
	Translate_to: "Oversæt til"
};