export const TRANSLATIONS_DE = {
	Logo_Title_Label: "Private Unterhaltung",
	Service_Assist_Label: "Service Assistent",
	Start_New_LabelBtn: "Neue Unterhaltung",
	Start_New_Question: "Wie wollen Sie ihre neue Unterhaltung starten?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Code",
	Channel_List: "Unterhaltung Liste",
	Chat_Label: "Chat",
	Call_Label: "Anruf",
	Video_Label: "Video",
	Share_Label: "teilen",
	Stop_Channel_Label: "Unterhaltung stoppen",
	Contact_Details_Label: "Kontaktdaten",
	Request_Details_Label: "Service Details",
	Attendees_Label: "Teilnehmer",
	Customer_Label: "Kunde",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Schreiben Sie eine Nachricht",
	Stop_Channel_Question: "Möchten Sie die Unterhaltung schließen?",
	Yes_Label: "Ja",
	Cancel_Label: "Abbrechen",
	Start_New_Video_Session: "Neue Video Unterhaltung starten",
	Without_SMS_Label: "Ohne SMS",
	Send_SMS_Label: "SMS senden",
	Send_Invite_Label: "Einladung senden",
	Log_Out_Question: "Möchten Sie sicht ausloggen?",
	Welcome_Label: "Willkomen zu der privaten Unterhaltung",
	Login_Label: "Login",
	Login_Is_Required_Label: "Login ist notwendig um eine Unterhatlung zu starten",
	Account_Permission_Label: "Dieser Account hat keine Berechtigung eine Unterhaltung zu starten",
	Start_Label: "Start",
	Noted_Recommend: "Aufgrund der Richtlinien von Bosch zur Verwendung von Firefox empfehlen wir Ihnen, einen anderen Browser zu verwenden, um das beste Nutzererlebnis für die private Unterhaltung zu erzielen. Empfohlene Browser: Microsoft Edge und Google Chrome",
	Available_Languages_Label: "vorhandene Sprachen",
	Can_Not_Create_Meeting_Title: "Nicht möglich eine neue private Unterhaltung zu starten",
	Can_Not_Create_Meeting_Content: "Sie sind noch in einer aktiven Unterhaltung, bitte schließen Sie diese um eine neue zu starten.",
	Leave_meeting_Warning: "Verlassen Sie die privaten Unterhaltung oder beenden Sie die Unterhaltung für alle. Sobald die Unterhaltung beendet ist, kann sie nicht mehr genutzt werden.",
	Leave_Lable: "Verlassen",
	End_Lable: "Ende",
	Meeting_Ended: "Die Unterhaltung wurde beendet",
	Start_Channel_Session: "Unterhaltung starten",
	Start_Channel_Session_Content: "Der Mechaniker benutzt aktuell eine andere Ticket ID",
	Join_Lable: "Beitreten",
	Join_Meeting_Label: "Unterhaltung beitreten",
	Meeting_Information_Label: "Unterhaltung Details",
	Meeting_ID_Label: "Unterhaltungs ID",
	Translate_Option_Title: "Übersetzungsoptionen",
	Translate_Mode_Label: "Übersetzungs Modus",
	From_Label: "Von:",
	To_Label: "An:",
	Chat_Room_Label: "private Unterhaltung",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Wie wollen Sie die Einladung für die private Unterhaltung versenden?",
	Invite_To_Visual_Question: "Wie wollen Sie die Einladung für Visual Connect​ versenden?",
	Invite_Sms_Question: "Einladung per SMS senden",
	Invite_To_Chat_By_Ticket_ID: "Einladung per Ticket ID senden",
	Invite_Email_Question: "Einladung per E-Mail senden",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Anwendungen",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Webseite betreten",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "Allgemeine Kontaktdaten",
	WSA_PSIRT_label: "Product security (PSIRT)",
	WSA_licences_label: "Innovationen, Patente und Lizenzen",
	WSA_purchasing_label: "Einkauf und Logistik",
	WSA_copyright_label: "© Robert Bosch GmbH, alle Rechte vorbehalten",
	WSA_copyright_information_label: "Unternehmensinformationen",
	WSA_copyright_notice_label: "Rechtliche Hinweise",
	WSA_copyright_protectionnotice_label: "Datenschutzhinweis",
	WSA_copyright_terms_label: "Allgemeine Geschäftsbedingungen",
	WSA_copyright_settings_label: "Datenschutzeinstellungen",
	Infor_CorporateInformation_Description: "Verantwortlich für die Webseiten der Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Datenschutzhinweise für den Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "Für Nutzer von Webseiten der Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Thema",
	Make_panel: "Marke",
	Model_pane: "Model",
	Engine_code_panel: "Motorcode",
	Year_panel: "Jahr",
	Vin_panel: "VIN",
	Plate_panel: "Kennzeichen",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Ihr Geröt ist nicht verfügbar für AR",
	Write_something: "Schreiben Sie etwas um die Unterhaltung zu starten",
	Session_title: "Sitzung",
	Live_Translation: "Live Übersetzung",
	Enabled_panel: "Vorhanden",
	Joined_note: "Sie sind der Sitzung beigetreten",
	Today_panel: "Heute",
	Read_panel: "Gelesen",
	Sent_panel: "Gesendet",
	Dis_translation: "Übersetzung deaktivieren",
	En_translation: "Übersetzung aktivieren",
	Terminated_Channel_note: "Der Bosch Experte hat die Unterhatlung beendet",
	Not_start_note: "noch nicht gestartet",
	Retry_note: "Bitte drücken Sie \"Wiederholen\" um es erneut zu versuchen oder schließen Sie das Fenster.",
	Retry_panel: "Wiederholen",
	Thanks_note: "Vielen Dank, dass Sie den Technischen Service von Bosch in Anspruch genommen haben. Ich freue mich sehr, dass wir heute die Gelegenheit hatten, Ihnen zu helfen. Bitte kontaktieren Sie uns erneut, wenn Sie Hilfe benötigen. Ich wünsche Ihnen einen schönen Tag.",
	Continue_panel: "Weiter",
	En_live_translate: "Live Übersetzung aktivieren",
	Translate_from: "Übersetzen von",
	Translate_to: "Übersetzen nach",
	Random_ID: "Zufällige ID",
	Anonymous: "Anonym",
	Generated_ID: "Zufällige ID wurde generiert!",
	Your_ID: "Ihre zufällige ID ist:"
};