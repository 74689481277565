export const TRANSLATIONS_FI = {
	Logo_Title_Label: "Private Channel Solution",
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Uusi kanava",
	Start_New_Question: "Kuinka haluat aloittaa uuden kanavan?",
	Sms_label: "SMS",
	Email_label: "Sähköposti",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Koodi",
	Channel_List: "Kanavalista",
	Chat_Label: "Chat",
	Call_Label: "Puhelu",
	Video_Label: "Video",
	Share_Label: "Jaa",
	Stop_Channel_Label: "Sulje kanava",
	Contact_Details_Label: "Yhteystiedot",
	Request_Details_Label: "Kyselyn tiedot",
	Attendees_Label: "Osallistujat",
	Customer_Label: "Asiakas",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Kirjoita viestisi",
	Stop_Channel_Question: "Haluatko sulkea kanavan?",
	Yes_Label: "Kyllä",
	Cancel_Label: "Peruuta",
	Start_New_Video_Session: "Aloita uusi videoyhteys",
	Without_SMS_Label: "Ilman SMS",
	Send_SMS_Label: "Lähetä SMS",
	Send_Invite_Label: "Lähetä kutsu",
	Log_Out_Question: "Haluatko kirjautua ulos?",
	Welcome_Label: "Tervetuloa Private Channel Solution -palveluun",
	Login_Label: "Sisäänkirjautuminen",
	Login_Is_Required_Label: "Sisäänkirjautuminen vaaditaan kanavan aloittamiseksi.",
	Account_Permission_Label: "Tällä tilillä ei ole oikeuksia aloittaa kanavaa.",
	Start_Label: "Aloita",
	Noted_Recommend: "Boschin Firefox-selaimeen liittyvien käytäntöjen vuoksi suosittelemme, että käytät muuta selainta parhaan käyttökokemuksen varmistamiseksi. Suositellut selaimet: Microsoft Edge & Google Chrome.",
	Available_Languages_Label: "Saatavilla olevat kielet",
	Can_Not_Create_Meeting_Title: "Kanavan aloittaminen epäonnistui.",
	Can_Not_Create_Meeting_Content: "Olet yhä aktiivisella kanavalla. Ole hyvä ja sulje avoin kanava ennen kuin aloitat uuden.",
	Leave_meeting_Warning: "Poistu kanavalta tai sulje se kaikilta. Kanavaa ei voida enää käyttää sulkemisen jälkeen.",
	Leave_Lable: "Poistu",
	End_Lable: "Sulje",
	Meeting_Ended: "Kanava on suljettu",
	Start_Channel_Session: "Aloita kanava",
	Start_Channel_Session_Content: "Mekaanikko käyttää parhaillaan toista Ticket ID:tä.",
	Join_Lable: "Liity",
	Join_Meeting_Label: "Liity kanavalle",
	Meeting_Information_Label: "Kanavan tiedot",
	Meeting_ID_Label: "Kanavan ID",
	Translate_Option_Title: "Kielikäännösvaihtoehdot:",
	Translate_Mode_Label: "Käännös:",
	From_Label: "Lähtökieli:",
	To_Label: "Kohdekieli:",
	Chat_Room_Label: "Private Channel",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Kuinka haluat jakaa Private Channel -kutsun?",
	Invite_To_Visual_Question: "Kuinka haluat jakaa Visual Connect -kutsun?",
	Invite_Sms_Question: "Lähetä SMS-kutsu",
	Invite_To_Chat_By_Ticket_ID: "Lähetä kutsu Ticket ID:lle",
	Invite_Email_Question: "Lähetä kutsu sähköpostilla",
	Link_label: "Linkki",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Sovellukset",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Syötä verkkosivu",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "Yleiset yhteystiedot",
	WSA_PSIRT_label: "Tuoteturvallisuus (PSIRT)",
	WSA_licences_label: "Innovaatiot, patentit ja lisenssit",
	WSA_purchasing_label: "Hankinta ja logistiikka",
	WSA_copyright_label: "© Robert Bosch GmbH, kaikki oikeudet pidätetään",
	WSA_copyright_information_label: "Yritystiedot",
	WSA_copyright_notice_label: "Oikeudellinen huomautus",
	WSA_copyright_protectionnotice_label: "Tietosuojakäytäntö",
	WSA_copyright_terms_label: "Käyttöehdot",
	WSA_copyright_settings_label: "Yksityisyysasetukset",
	Infor_CorporateInformation_Description: "Vastuullinen Robert Bosch GmbH:n internetsivustoista",
	Infor_DataProtectionNotice_Description: "Bosch Remote Diagnostics -palvelun tietosuojakäytäntö",
	Infor_LegalNotice_Description: "Robert Bosch GmbH:n internetsivujen käyttäjille",
	Id_panel: "Id",
	Topic_panel: "Aihe",
	Make_panel: "Valmistaja",
	Model_pane: "Malli",
	Engine_code_panel: "Moottoritunnus",
	Year_panel: "Vuosimalli",
	Vin_panel: "VIN",
	Plate_panel: "Rek.nro",
	Hardware_panel: "Laite",
	ar_mode_warning: "Laite ei ole saatavilla AR:lle.",
	Write_something: "Kirjoita aloittaaksesi keskustelun.",
	Session_title: "Istunto",
	Live_Translation: "Live käännös",
	Enabled_panel: "Aktivoitu",
	Joined_note: "Olet liittynyt istuntoon",
	Today_panel: "Tänään",
	Read_panel: "Luettu",
	Sent_panel: "Lähetetty",
	Dis_translation: "Deaktivoi käännös",
	En_translation: "Aktivoi käännös",
	Terminated_Channel_note: "Bosch Expert on sulkenut kanavan.",
	Not_start_note: "ei ole vielä alkanut.",
	Retry_note: "Valitse \"Toista\" yrittääksesi uudelleen tai sulje tämä ikkuna poistuaksesi.",
	Retry_panel: "Toista",
	Thanks_note: "Kiitos kun käytit Boschin Teknisiä Palveluita. Olemme erittäin kiitollisia siitä, että saimme mahdollisuuden auttaa sinua tänään. Ota yhteyttä uudelleen kun taas tarvitset apua. Hyvää päivänjatkoa!",
	Continue_panel: "Aktivoi live-käännös",
	En_live_translate: "Jatka",
	Translate_from: "Lähtökieli",
	Translate_to: "Kohdekieli",
	Random_ID: "Satunnainen ID",
	Anonymous: "Nimetön",
	Generated_ID: "Satunnainen ID on luotu!",
	Your_ID: "Satunnainen ID on:"
};